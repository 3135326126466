import Vue from "vue";
import Vuex from "vuex";
import admin from "./modules/admin";
import auth from "./modules/auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    admin,
    auth,
  },

  /*Boilerplate code*/
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
