<template>
  <v-app id="dashboard" :class="returnRouteName()">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-if="$router.currentRoute.name !== 'login'"
      color="#1565c0"
    >
      <v-list-item class="px-2 header-draw">
        <v-avatar class="avatar" color="white" size="40">
          <span class="headline">MM</span>
        </v-avatar>
        <v-list-item-content>
          <v-list-item-title> Marko Milićević </v-list-item-title>
          <v-list-item-subtitle>administrator</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense>
        <router-link v-for="(item, i) in items" :key="i" :to="{ name: item.route }">
          <v-list-item
            :key="item.title"
            link
            :class="$router.currentRoute.name === item.route ? 'v-list-item--active' : ''"
            :inactive="false"
          >
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-item class="logout-btn" @click="logout">
          <v-list-item-icon>
            <v-icon>
              mdi-login-variant
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="admin-content">
      <v-container fluid fill-height>
        <v-layout child-flex xs12>
          <router-view></router-view>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: "DashboardLayout",
  data: () => ({
    drawer: null,
    items: [
      {
        title: "All Websites",
        icon: "mdi-earth",
        route: "all-websites",
      },
      {
        title: "Keyword database",
        icon: "mdi-cellphone-iphone",
        route: "keyword-database",
      },
    ],
    mini: false,
  }),
  computed: {},
  watch: {},
  created() {},
  methods: {
    returnRouteName() {
      return this.$route.name;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        window.location.reload();
      });
    },
  },
};
</script>
<style lang="scss">
.header-draw {
  .v-list-item__title {
    font-weight: bold;
  }
  .v-list-item__subtitle {
    font-size: 13px !important;
  }
}

#dashboard {
  .v-list-item--active {
    background: #2a73c5 !important;
  }
  .v-navigation-drawer__content div {
    font-size: 14px;
    color: #fff;

    a {
      text-decoration: none;
    }
  }

  .v-navigation-drawer__content {
    .mdi-chevron-left::before {
      color: #fff !important;
    }
  }

  hr {
    border-color: transparent;
  }

  .v-list-item {
    padding: 10px 10px;
    margin: 0 10px;
  }

  .v-list-item__icon {
    position: relative;
  }

  .v-navigation-drawer--mini-variant .v-list-item {
    margin: 0 !important;
  }

  .v-navigation-drawer__content {
    .v-icon::before {
      color: #fff;
    }
  }

  .theme--light.v-list-item--active:before {
    border-radius: 4px;
    opacity: 1;
    background: #6a7c8a;
    z-index: -1;
  }

  .v-list-item__icon:first-child {
    margin-right: 18px;
  }

  .header-draw {
    padding: 16px;
  }

  .header-draw .avatar {
    margin-right: 16px;
  }

  .header-draw .headline {
    color: #1565c0;
    font-size: 16px !important;
    font-weight: bold;
  }

  .header-draw span {
    font-size: 12px;
  }
}
</style>
