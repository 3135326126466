import api from "../../api/ManageApi";
import errorHandler from "../../helpers/errorHandling";

export default {
  state: {
    allWebsites: [],
    allKeywords: [],
  },
  getters: {
    getAllWebsites(state) {
      return state.allWebsites;
    },
    getAllKeywords(state) {
      return state.allKeywords;
    },
  },
  mutations: {
    setAllWebsites(state, payload) {
      state.allWebsites = payload;
    },
    setAllKeywords(state, payload) {
      state.allKeywords = payload;
    },
    updateAllKeywords(state, payload) {
      state.allKeywords[0].updated_at = payload.updated_at;
      state.allKeywords[0].name = payload.name;
    },
  },
  actions: {
    getAllWebsites({ commit }, payload) {
      return api
        .getAllWebsites(payload)
        .then((response) => {
          commit("setAllWebsites", response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    getAllKeywords({ commit }, payload) {
      return api
        .getAllKeywords(payload)
        .then((response) => {
          console.log(response.data);
          commit("setAllKeywords", response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    uploadKeywordDatabase({ commit }, payload) {
      return api
        .uploadKeywordDatabase(payload)
        .then((response) => {
          commit("updateAllKeywords", response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    downloadKeywordFile(context, payload) {
      return api
        .downloadKeywordFile(payload.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.ms-excel" }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", payload.name);
          document.body.appendChild(link);
          link.click();

          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    downloadWebsites(context, payload) {
      return api
        .downloadWebsites(payload.id)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.ms-excel" }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", payload.name === "all" ? "FullReport.zip" : payload.name + "Prices.xlsx");
          document.body.appendChild(link);
          link.click();

          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    fetchAll() {
      return api
        .fetchAll()
        .then((response) => {
          console.log(response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    fetchWebsite(context, payload) {
      return api
        .fetchWebsite(payload)
        .then((response) => {
          console.log(response);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    stopFetchWebsite(context, payload) {
      return api
        .stopFetchWebsite(payload)
        .then((response) => {
          console.log(response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    stopFetchAll() {
      return api
        .stopFetchAll()
        .then((response) => {
          console.log(response.data);
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
  },
  namespaced: true,
};
