<template>
  <div class="login-page">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-flex xs12 sm8 md4 lg4>
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                Login
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <div v-if="errors" class="error-container">
                  <span class="error2">{{ errors }}</span>
                </div>
                <v-text-field
                  label="Email"
                  name="login"
                  placeholder="Email"
                  prepend-icon="mdi-mail"
                  type="text"
                  v-model="email"
                  @keyup.enter.native="login"
                ></v-text-field>
                <v-text-field
                  id="password"
                  label="Password"
                  placeholder="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="password"
                  @keyup.enter.native="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-snackbar v-model="snackbar" color="error">
          {{ snackbarText }}
          <v-btn dark text @click="snackbar = false">Zatvori</v-btn>
        </v-snackbar>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errors: "",
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {},
  methods: {
    login() {
      let email = this.email;
      let password = this.password;
      this.$store.dispatch("auth/login", { email: email, password: password }).then((response) => {
        if (response.status) {
          this.errors = "";
          this.$router.push({ name: "all-websites" });
        } else {
          this.errors = response.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.login-page {
  > div .row,
  > div {
    height: 100%;
  }
  .error2 {
    background-color: transparent !important;
    font-weight: bold;
    color: #ff3f49;
    margin-bottom: 0;
  }
  .error-container {
    padding-left: 2rem;
    padding-bottom: 1rem;
  }
}
</style>
