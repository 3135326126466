import { baseRequest } from "./Api";

export default {
  login(payload) {
    return baseRequest.post("v1/auth/login", payload).then((response) => response.data);
  },
  getAllWebsites(payload) {
    return baseRequest.get("v1/admin/websites", payload).then((response) => response.data);
  },
  getAllKeywords(payload) {
    return baseRequest.get("v1/admin/keywords", payload).then((response) => response.data);
  },
  uploadKeywordDatabase(payload) {
    return baseRequest
      .post("v1/admin/keywords/import", payload, { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => response.data);
  },
  downloadKeywordFile(payload) {
    return baseRequest
      .get("v1/admin/keywords/download_excel/" + payload, { responseType: "blob" })
      .then((response) => response.data);
  },

  downloadWebsites(payload) {
    return baseRequest
      .get("v1/admin/websites/download/" + payload, { responseType: "blob" })
      .then((response) => response.data);
  },
  fetchAll() {
    return baseRequest.get("v1/admin/scrape/all").then((response) => response.data);
  },
  fetchWebsite(payload) {
    return baseRequest.get("v1/admin/scrape/" + payload).then((response) => response.data);
  },
  stopFetchWebsite(payload) {
    return baseRequest.post("v1/admin/scrape/stop", { website: payload }).then((response) => response.data);
  },
  stopFetchAll() {
    return baseRequest.get("v1/admin/stop/all").then((response) => response.data);
  },
};
