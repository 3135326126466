<template>
  <div class="keywords-page">
    <v-card class="keywords-page-wrapper">
      <v-row>
        <v-col lg="12">
          <h3 class="um-title">
            Keywords database
          </h3>
          <p class="um-subtitle">
            Upload Excel documents.
          </p>
        </v-col>
        <v-row style="padding: 0 25px 50px 15px;">
          <v-col lg="6">
            <v-alert v-if="errors" type="error" :dismissible="true" style="margin:0;max-width:553px">
              {{ errors }}
            </v-alert>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-btn
              :loading="loadingUpload"
              @click="$refs.fileUpload.click()"
              large
              color="#1976d2"
              class="white--text add-button"
            >
              <v-icon left>mdi-file-plus</v-icon>UPLOAD EXCEL DOCUMENT
            </v-btn>
            <input style="display: none;" type="file" ref="fileUpload" @change="newFile" accept=".xlsx, .xls, .csv" />
          </v-col>
        </v-row>
      </v-row>
      <v-data-table :headers="headers" :items-per-page="7" :items="getAllKeywords" class="elevation-1">
        <template v-slot:top> </template>
        <template v-slot:item.icon="{}">
          <div
            style="background: #bdbdbd;margin: 0 auto;border-radius: 50%;display: flex;text-align: center;justify-content:center;width: 30px;height:30px;text-align:center;"
          >
            <v-icon style="font-size:17px" color="#fff">mdi-file-document</v-icon>
          </div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ moment(item.updated_at).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.download="{ item }">
          <v-btn @click="downloadFile(item.id, item.name)" style="background:none;border:none;box-shadow:none;" :loading="loadingDownload">
            <v-icon color="#9e9e9e">mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar" color="success">
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "KeywordDatabase",
  data() {
    return {
      dialog: false,
      snackbar: false,
      snackbarText: "",
      errors: "",
      uploadFile: null,
      loadingUpload: false,
      loadingDownload: false,
      headers: [
        {
          text: "",
          value: "icon",
          sortable: false,
          align: "center",
          width: "5%",
        },
        {
          text: "Document name",
          value: "name",
          sortable: false,
        },
        {
          text: "Upload date",
          value: "updated_at",
          sortable: false,
          align: "center",
        },
        {
          text: "Download",
          value: "download",
          sortable: false,
          align: "center",
        },
      ],
      dummyData: [
        {
          id: 1,
          name: "Keywords.xslx",
          updated_at: "01.03.2020.",
        },
      ],
    };
  },
  computed: {
    getAllKeywords() {
      return this.$store.getters["admin/getAllKeywords"];
    },
  },
  created() {
    this.errors = "";
    this.$store.dispatch("admin/getAllKeywords").then((response) => {
      if (response.status) {
        this.errors = "";
      } else {
        this.errors = response.data;
      }
    });
  },
  methods: {
    newFile(event) {
      this.loadingUpload = true;
      var input = event.target;
      this.photo = input.files[0];

      let data = new FormData();
      data.append("keywords", this.photo);

      this.$store.dispatch("admin/uploadKeywordDatabase", data).then((response) => {
        if (response.status) {
          console.log(response);
          this.loadingUpload = false;
          this.snackbarText = response.data.message;
          this.snackbar = true;
          this.errors = "";
        } else {
          this.loadingUpload = false;
          this.errors = response.data;
        }
      });
    },
    downloadFile(id, name) {
      this.$store.dispatch("admin/downloadKeywordFile", { id, name }).then((response) => {
        if (response.status) {
          console.log(response);
          this.snackbarText = "File downloaded successfully.";
          this.snackbar = true;
          this.errors = "";
        } else {
          this.loadingUpload = false;
          this.errors = response.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.v-alert__content {
  max-width: 80%;
}
.v-application .keywords-page .elevation-1 {
  box-shadow: none !important;
  border: none;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 74%;
}
.progressWrapper {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-weight: bold;
  }
}
.keywords-page {
  .keywords-page-wrapper {
    height: 100%;
  }
  table td {
    color: #333333;
  }
  table th span {
    color: #333333;
  }
  .um-title {
    font-size: 32px;
    color: #424242;
    text-decoration: none solid rgb(0, 0, 0);
  }
  .um-subtitle {
    font-size: 16px;
    color: #9e9e9e;
    text-decoration: none solid rgb(142, 142, 147);
  }
  .add-button {
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
  .keywords-page-wrapper {
    padding: 1rem;
  }
  .active {
    color: #a70532;
  }
  .finished {
    color: #333333;
  }
}
</style>
