import api from "../../api/ManageApi";
import JwtService from "../../api/JwtService";
import errorHandler from "../../helpers/errorHandling";

const getDefaultState = () => {
  return {
    isAuthenticated: !!JwtService.getToken(),
  };
};

export default {
  state: {
    isAuthenticated: !!JwtService.getToken(),
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },
  mutations: {
    setAuth(state, data) {
      state.isAuthenticated = true;
      JwtService.setToken(data.token);
      JwtService.setRefreshToken(data.refreshToken);
      JwtService.setAxiosToken();
      Object.assign(state, getDefaultState());
    },
    resetAuth(state) {
      JwtService.unsetToken();
      JwtService.unsetRefreshToken();
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    login({ commit }, payload) {
      return api
        .login(payload)
        .then((response) => {
          commit("setAuth", {
            token: response.access_token,
            refreshToken: response.refresh_token,
          });
          return { status: true, data: response };
        })
        .catch((error) => {
          return { status: false, data: errorHandler.extractErrors(error) };
        });
    },
    logout({ commit }) {
      commit("resetAuth");
      return true;
    },
    checkAuth({ commit }) {
      if (!JwtService.getToken()) {
        commit("resetAuth");
        return false;
      }

      return true;
    },
  },
  namespaced: true,
};
