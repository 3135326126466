<template>
  <div class="websites-page">
    <v-card class="websites-page-wrapper">
      <v-row>
        <v-col lg="12">
          <h3 class="um-title">
            All Websites
          </h3>
          <p class="um-subtitle">
            Websites overview.
          </p>
        </v-col>
        <v-row style="padding: 0 25px 50px 15px;">
          <v-spacer></v-spacer>
          <v-btn v-if="!fetchAllStarted" large color="#8bc34a" class="white--text add-button" @click="fetchAll">
            <v-icon left>mdi-play</v-icon>FETCH ALL
          </v-btn>
          <v-btn v-else large color="#b71c1c" class="white--text add-button" @click="stopFetchFile('all')">
            <v-icon left>mdi-stop</v-icon>STOP ALL
          </v-btn>

          <v-btn
            :disabled="disableDownloadAll"
            :loading="loadingDownload"
            @click="downloadFile('all', 'all')"
            large
            style="margin-left:1rem"
          >
            <v-icon color="#9e9e9e">mdi-download</v-icon>
          </v-btn>
        </v-row>
      </v-row>
      <v-data-table :headers="headers" :items-per-page="7" :items="getAllWebsites" class="elevation-1">
        <template v-slot:top> </template>
        <template v-slot:item.slug="{ item }">
          <div
            style="background: #1565c0;margin: 0 auto;border-radius: 50%;display: flex;text-align: center;justify-content:center;width: 30px;height:30px;text-align:center;"
          >
            <span style="color:#fff;font-size:13px;margin:0;align-self: center;">{{ getSlug(item.company_name) }}</span>
          </div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ moment(item.updated_at).format("DD/MM/YYYY") }}
        </template>
        <template v-slot:item.status="{ item }">
          <div v-if="!item.completed" class="progressWrapper">
            <v-progress-linear color="#1565c0" buffer-value="0" :value="item.progress" stream> </v-progress-linear>
            <span>{{ item.progress }}%</span>
          </div>

          <div v-else>
            <div v-if="item.failed">Failed at {{ moment(item.updated_at).format("HH:mm:ss") }}</div>
            <div v-else>
              <div v-if="item.is_canceled">Canceled at {{ moment(item.updated_at).format("HH:mm:ss") }}</div>
              <div v-else>Finished at {{ moment(item.updated_at).format("HH:mm:ss") }}</div>
            </div>
          </div>
        </template>
        <template v-slot:item.website_url="{ item }">
          <a :href="item.website_url" style="color:#333333;" target="_blank">{{ item.website_url }}</a>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            v-if="item.completed"
            @click="fetchFile(item.id)"
            small
            color="#8bc34a"
            style="font-size: 12px;"
            class="white--text add-button"
          >
            <v-icon left>mdi-play</v-icon>FETCH
          </v-btn>
          <v-btn
            v-else
            @click="stopFetchFile(item.id)"
            style="font-size: 12px;"
            small
            color="#b71c1c"
            class="white--text add-button"
          >
            <v-icon left>mdi-stop</v-icon>STOP
          </v-btn>
        </template>
        <template v-slot:item.download="{ item }">
          <v-btn
            :disabled="!Boolean(item.completed)"
            @click="downloadFile(item.id, item.company_name)"
            style="background:none;border:none;box-shadow:none;"
            :loading="loadingDownload"
          >
            <v-icon color="#9e9e9e">mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar v-model="snackbar" color="success">
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "AllWebsites",
  data() {
    return {
      dialog: false,
      snackbar: false,
      snackbarText: "",
      loadingDownload: false,
      loaderPercentage: 30,
      fetchAllStarted: false,
      disableDownloadAll: false,
      headers: [
        {
          text: "",
          value: "slug",
          sortable: false,
        },
        {
          text: "Company name",
          value: "company_name",
          sortable: false,
        },
        {
          text: "Website URL",
          value: "website_url",
          sortable: false,
          width: "15%",
        },
        {
          text: "Updated at",
          value: "updated_at",
          sortable: false,
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "center",
          width: "20%",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
        {
          text: "Download",
          value: "download",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  computed: {
    getAllWebsites() {
      return this.$store.getters["admin/getAllWebsites"];
    },
  },
  watch: {
    getAllWebsites(val) {
      let started = 0;

      val.forEach((item) => {
        if (item.completed === 0) {
          started++;
        }
      });

      if (started === val.length) {
        this.fetchAllStarted = true;
      }
      this.disableDownloadAll = true;

      if (started === 0) {
        this.fetchAllStarted = false;
        this.disableDownloadAll = false;
      }

      if (started !== 0) {
        setTimeout(() => {
          this.apiGetAllWebsites();
        }, 5000);
      }
    },
  },
  created() {
    this.apiGetAllWebsites();
  },
  methods: {
    apiGetAllWebsites() {
      this.$store.dispatch("admin/getAllWebsites").then((response) => {
        if (response.status) {
          this.errors = "";
        } else {
          this.errors = response;
        }
      });
    },
    getSlug(name) {
      let initials = name.match(/\b\w/g) || [];
      initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
      return initials;
    },
    downloadFile(id, name) {
      this.$store
        .dispatch("admin/downloadWebsites", { id: id === "all" ? "all" : "one/" + id, name: name })
        .then((response) => {
          if (response.status) {
            this.snackbarText = "File downloaded successfully.";
            this.snackbar = true;
            this.errors = "";
          } else {
            this.loadingUpload = false;
            this.errors = response.data;
          }
        });
    },
    fetchFile(id) {
      this.$store.dispatch("admin/fetchWebsite", "one/" + id).then((response) => {
        if (response.status) {
          this.apiGetAllWebsites();
          this.snackbarText = "Fetch started successfully.";
          this.snackbar = true;
          this.errors = "";
        } else {
          this.errors = response.data;
        }
      });
    },
    stopFetchFile(id) {
      this.$store.dispatch("admin/stopFetchWebsite", id === "all" ? "all" : id).then((response) => {
        if (response.status) {
          this.apiGetAllWebsites();
          this.snackbarText = "Job stopped successfully.";
          this.snackbar = true;
          this.errors = "";
        } else {
          this.errors = response.data;
        }
      });
    },
    fetchAll() {
      this.$store.dispatch("admin/fetchAll").then((response) => {
        if (response.status) {
          this.fetchAllStarted = true;
          this.apiGetAllWebsites();
          this.errors = "";
        } else {
          this.errors = response.data;
        }
      });
    },
    downloadAll() {
      this.$store.dispatch("admin/downloadAllWebsites").then((response) => {
        if (response.status) {
          this.snackbarText = "File downloaded successfully.";
          this.snackbar = true;
          this.errors = "";
        } else {
          this.loadingUpload = false;
          this.errors = response.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.v-snack__content {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.v-application .websites-page .elevation-1 {
  box-shadow: none !important;
  border: none;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 74%;
}
.progressWrapper {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    font-weight: bold;
  }
}
.websites-page {
  .websites-page-wrapper {
    height: 100%;
  }
  table td {
    color: #333333;
  }
  table th span {
    color: #333333;
  }
  .um-title {
    font-size: 32px;
    color: #424242;
    text-decoration: none solid rgb(0, 0, 0);
  }
  .um-subtitle {
    font-size: 16px;
    color: #9e9e9e;
    text-decoration: none solid rgb(142, 142, 147);
  }
  .add-button {
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
  .websites-page-wrapper {
    padding: 1rem;
  }
  .active {
    color: #a70532;
  }
  .finished {
    color: #333333;
  }
}
</style>
