import Vue from "vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import moment from "moment";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import DashboardLayout from "./screens/DashboardLayout";
import VueGtm from "vue-gtm";

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.use(Vuetify);

console.log(process.env.NODE_ENV);

Vue.use(VueGtm, {
  id: "GTM-WMPDH9J",
  enabled: process.env.NODE_ENV === "production",
  vueRouter: router,
});

new Vue({
  render: (h) => h(DashboardLayout),
  store,
  router,
  vuetify: new Vuetify(),
  components: { DashboardLayout },
}).$mount("#app");
