import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import Login from "../screens/Login";
import AllWebsites from "../screens/AllWebsites";
import KeywordDatabase from "../screens/KeywordDatabase";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      redirect: "/all-websites",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Mobi Parts Admin | Login",
        requiresAuth: false,
      },
    },
    {
      path: "/all-websites",
      name: "all-websites",
      component: AllWebsites,
      meta: {
        title: "Mobi Parts Admin | All Websites",
        requiresAuth: true,
      },
    },
    {
      path: "/keyword-database",
      name: "keyword-database",
      component: KeywordDatabase,
      meta: {
        title: "Mobi Parts Admin | Kyword Database",
        requiresAuth: true,
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "login" && store.getters["auth/isAuthenticated"]) {
    next({ name: "all-websites" });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  let access = store.getters["auth/isAuthenticated"];

  if (to.meta.requiresAuth) {
    if (access) {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    if (to.name !== null) {
      next();
    } else {
      next({ name: "all-websites" });
    }
  }
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

router.beforeEach((to, from, next) => {
  store.dispatch("auth/checkAuth").then(() => {
    next();
  });
});
export default router;
