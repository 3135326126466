var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"websites-page"},[_c('v-card',{staticClass:"websites-page-wrapper"},[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('h3',{staticClass:"um-title"},[_vm._v(" All Websites ")]),_c('p',{staticClass:"um-subtitle"},[_vm._v(" Websites overview. ")])]),_c('v-row',{staticStyle:{"padding":"0 25px 50px 15px"}},[_c('v-spacer'),(!_vm.fetchAllStarted)?_c('v-btn',{staticClass:"white--text add-button",attrs:{"large":"","color":"#8bc34a"},on:{"click":_vm.fetchAll}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-play")]),_vm._v("FETCH ALL ")],1):_c('v-btn',{staticClass:"white--text add-button",attrs:{"large":"","color":"#b71c1c"},on:{"click":function($event){return _vm.stopFetchFile('all')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-stop")]),_vm._v("STOP ALL ")],1),_c('v-btn',{staticStyle:{"margin-left":"1rem"},attrs:{"disabled":_vm.disableDownloadAll,"loading":_vm.loadingDownload,"large":""},on:{"click":function($event){return _vm.downloadFile('all', 'all')}}},[_c('v-icon',{attrs:{"color":"#9e9e9e"}},[_vm._v("mdi-download")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items-per-page":7,"items":_vm.getAllWebsites},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"background":"#1565c0","margin":"0 auto","border-radius":"50%","display":"flex","text-align":"center","justify-content":"center","width":"30px","height":"30px"}},[_c('span',{staticStyle:{"color":"#fff","font-size":"13px","margin":"0","align-self":"center"}},[_vm._v(_vm._s(_vm.getSlug(item.company_name)))])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("DD/MM/YYYY"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(!item.completed)?_c('div',{staticClass:"progressWrapper"},[_c('v-progress-linear',{attrs:{"color":"#1565c0","buffer-value":"0","value":item.progress,"stream":""}}),_c('span',[_vm._v(_vm._s(item.progress)+"%")])],1):_c('div',[(item.failed)?_c('div',[_vm._v("Failed at "+_vm._s(_vm.moment(item.updated_at).format("HH:mm:ss")))]):_c('div',[(item.is_canceled)?_c('div',[_vm._v("Canceled at "+_vm._s(_vm.moment(item.updated_at).format("HH:mm:ss")))]):_c('div',[_vm._v("Finished at "+_vm._s(_vm.moment(item.updated_at).format("HH:mm:ss")))])])])]}},{key:"item.website_url",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"color":"#333333"},attrs:{"href":item.website_url,"target":"_blank"}},[_vm._v(_vm._s(item.website_url))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.completed)?_c('v-btn',{staticClass:"white--text add-button",staticStyle:{"font-size":"12px"},attrs:{"small":"","color":"#8bc34a"},on:{"click":function($event){return _vm.fetchFile(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-play")]),_vm._v("FETCH ")],1):_c('v-btn',{staticClass:"white--text add-button",staticStyle:{"font-size":"12px"},attrs:{"small":"","color":"#b71c1c"},on:{"click":function($event){return _vm.stopFetchFile(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-stop")]),_vm._v("STOP ")],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"background":"none","border":"none","box-shadow":"none"},attrs:{"disabled":!Boolean(item.completed),"loading":_vm.loadingDownload},on:{"click":function($event){return _vm.downloadFile(item.id, item.company_name)}}},[_c('v-icon',{attrs:{"color":"#9e9e9e"}},[_vm._v("mdi-download")])],1)]}}])})],1),_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }